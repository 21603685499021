import { Box, HStack, VStack } from "@chakra-ui/layout"
import React from "react"
import ZiptiText from "../zipti_text"
import AttachmentIcon from "../../../images/attachmentIcon.svg"
import ZiptiHStack from "../zipti_hstack"

interface LinkEventProps {
  eventLinks: Object | undefined
  eventDate: string
  isAccount: boolean
}

const LinkEvent: React.FC<LinkEventProps> = props => {
  return (
    <Box
      mb="2rem"
      mt="2rem"
      w={{ base: "68%", md: "45%" }}
      left={
        !props.isAccount ? { base: "3%", md: "3%" } : { base: "30%", md: "52%" }
      }
      position="relative"
    >
      {props.eventLinks.link_title_1 ? (
        <ZiptiHStack>
          {props.eventLinks.link_title_1 ? (
            <img src={AttachmentIcon} alt="attach icon" />
          ) : null}
          <VStack style={{ overflow: "hidden" }}>
            <ZiptiText>
              <a href={props.eventLinks.link_1} target="_toBlank">
                {props.eventLinks.link_title_1}
              </a>
            </ZiptiText>
          </VStack>
        </ZiptiHStack>
      ) : null}
      {props.eventLinks.link_title_2 ? (
        <ZiptiHStack>
          {props.eventLinks.link_title_2 ? (
            <img src={AttachmentIcon} alt="attach icon" />
          ) : null}
          <VStack style={{ overflow: "hidden" }}>
            <ZiptiText>
              <a href={props.eventLinks.link_2} target="_toBlank">
                {props.eventLinks.link_title_2}
              </a>
            </ZiptiText>
          </VStack>
        </ZiptiHStack>
      ) : null}
      {props.eventLinks.link_title_3 ? (
        <ZiptiHStack>
          {props.eventLinks.link_title_3 ? (
            <img src={AttachmentIcon} alt="attach icon" />
          ) : null}
          <VStack style={{ overflow: "hidden" }}>
            <ZiptiText>
              <a href={props.eventLinks.link_3} target="_toBlank">
                {props.eventLinks.link_title_3}
              </a>
            </ZiptiText>
          </VStack>
        </ZiptiHStack>
      ) : null}
      <ZiptiText color="gray.400" fontSize="1.5vh">
        {props.eventDate}
      </ZiptiText>
    </Box>
  )
}

export default LinkEvent
