import React from "react"
import { Box } from "@chakra-ui/layout"

const AskEventsWrapper: React.FC<any> = (props: any) => (
  <Box bgColor="#fefeff" {...props}>
    {props.children}
  </Box>
)

export default AskEventsWrapper
