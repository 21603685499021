import React from "react"

import { HStack } from "@chakra-ui/layout"

const ZiptiHStack: React.FC = props => {
  return (
    <HStack
      color="purple"
      fontSize="1.5vh"
      position="relative"
      backgroundColor="white.300"
      borderRadius="8px"
      boxShadow="md"
      mb="1rem"
      p="1rem"
      {...props}
    />
  )
}

export default ZiptiHStack
