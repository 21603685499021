import { Image } from "@chakra-ui/image"
import { Box } from "@chakra-ui/layout"
import { HStack } from "@chakra-ui/react"
import React from "react"
import { BsFillStarFill } from "react-icons/bs"
import { COLORS } from "../zipti_colors"
import ZiptiText from "../zipti_text"

interface ReviewEventProps {
  reviewComment?: string
  eventCopy: string
  eventDate: string
  reviewRating?: number
  mt?: string
}

const ReviewEvent: React.FC<ReviewEventProps> = props => {
  const reviews = () => {
    const starArray = []
    for (let i = 0; i < props.reviewRating; i++) {
      starArray.push(<BsFillStarFill fill={COLORS.GOLD} h="20px" w="20px" />)
    }
    return starArray
  }

  return (
    <Box
      w="100vw"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      position="relative"
      {...props}
    >
      <ZiptiText mb=".5rem" color="#211358" fontWeight="bold">
        {props.eventCopy}
      </ZiptiText>
      <HStack mb=".5rem" justifyContent="center">
        {reviews()}
      </HStack>
      <ZiptiText mb=".5rem">{props.reviewComment}</ZiptiText>
      <ZiptiText color="gray.400" fontSize="1.5vh">
        {props.eventDate}
      </ZiptiText>
    </Box>
  )
}

export default ReviewEvent
