import React from "react"
import { Box, Center } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"
import clearHeartIcon from "../../images/clearHeartIcon.svg"
import thanksIcon from "../../images/thanksIcon.svg"
import ratingStar from "../../images/ratingStarIcon.svg"
import unreviewedStarIcon from "../../images/unreviewedStarIcon.svg"
import ZiptiText from "../shared/zipti_text"
import { ApiContext } from "../user_wrapper"
import { useContext } from "react"
import { useState } from "react"
import { useEffect } from "react"

interface ButtonControllerProps {
  onClickReview: Function
  mt?: string | undefined
  askId: string
  setReviewId: Function
  setReviewed: Function
  reviewed: boolean
}

const ButtonController: React.FC<ButtonControllerProps> = props => {
  const [thanked, setThanked] = useState<boolean>(false)
  const apiClient = useContext(ApiContext)

  const getReviewAndThanksStatus = async () => {
    const res = await apiClient.getAsk(props.askId)
    console.log("BNTCTRL", res)
    res.data?.ask.ask_events.forEach(askEvent => {
      if (askEvent.event_name === "say_thanks") setThanked(true)
    })
    props.setReviewed(res.data?.ask.review_score ? true : false)
  }

  useEffect(() => {
    getReviewAndThanksStatus()
  }, [])

  return (
    <Box position="relative" h="12vh" w="100vw" {...props}>
      <Center
        position="absolute"
        left="30vw"
        h="6vh"
        w="6vh"
        bgColor="#4329AE"
        borderRadius="full"
      >
        <Image
          onClick={async () => {
            if (!thanked) {
              const res = await apiClient.sayThanks(props.askId)
            }
            setThanked(true)
          }}
          h="3vh"
          w="3vh"
          cursor="pointer"
          src={thanked ? thanksIcon : clearHeartIcon}
        />
        <ZiptiText position="absolute" top="7vh" fontSize="1.5vh">
          Thanks!
        </ZiptiText>
      </Center>
      <Center
        position="absolute"
        left="60vw"
        h="6vh"
        w="6vh"
        bgColor="#4329AE"
        borderRadius="full"
      >
        <Image
          h="3vh"
          w="3vh"
          cursor="pointer"
          src={props.reviewed ? ratingStar : unreviewedStarIcon}
          onClick={() => {
            props.onClickReview()
            props.setReviewId(props.askId)
          }}
        />
        <ZiptiText position="absolute" top="7vh" fontSize="1.5vh">
          Review
        </ZiptiText>
      </Center>
    </Box>
  )
}

export default ButtonController
