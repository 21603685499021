import { Avatar } from "@chakra-ui/avatar"
import { Container, HStack } from "@chakra-ui/layout"
import React from "react"
import ZiptiText from "../zipti_text"
import ZiptiTextarea from "../zipti_textarea"
import ratingStarIcon from "../../../images/ratingStarIcon.svg"
import { BsFillStarFill } from "react-icons/bs"
import { Image } from "@chakra-ui/image"
import ZiptiButton from "../zipti_button"
import { useContext } from "react"
import { ApiContext } from "../../user_wrapper"
import { useState } from "react"
import "./ask_review.css"
import { useEffect } from "react"

export interface AskReviewProps {
  profileImageUrl?: string
  onClickSubmit: Function
  reviewId: string | undefined
  profileName: string | undefined
  setReviewed: Function
}

const AskReview: React.FC<AskReviewProps> = (props: AskReviewProps) => {
  const [rating, setRating] = useState<number>(0)
  const [review, setReview] = useState<string>("")
  const apiClient = useContext(ApiContext)
  const [fade, setFade] = useState(0)
  const stateObject = {
    placeholderCopy: "Add a short message about your experience!",
  }

  const getRatingFromApi = async () => {
    const res = await apiClient.getAsk(props.reviewId)
    setRating(Number(res.data?.ask.review_score))
  }

  useEffect(() => {
    getRatingFromApi()
  }, [])

  return (
    <Container centerContent>
      <Avatar mt="15vh" h="10vh" w="10vh" src={props.profileImageUrl} />
      <ZiptiText mt="2vh" fontWeight="bold">
        Rating
      </ZiptiText>
      <ZiptiText color="gray.400" fontSize="1.5vh">
        How would you rate your answer from {props.profileName}?
      </ZiptiText>
      <HStack mt="2vh">
        {rating >= 1 ? (
          <BsFillStarFill
            cursor="pointer"
            className="emptyStar"
            onClick={() => {
              setTimeout(() => setRating(1), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            fill="#F7B500"
            size="34px"
          />
        ) : (
          <Image
            className="filledStar"
            h="4vh"
            w="4vh"
            onClick={() => {
              setTimeout(() => setRating(1), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            cursor="pointer"
            src={ratingStarIcon}
          />
        )}
        {rating >= 2 ? (
          <BsFillStarFill
            cursor="pointer"
            className="emptyStar"
            onClick={() => {
              setTimeout(() => setRating(2), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            fill="#F7B500"
            size="34px"
          />
        ) : (
          <Image
            h="4vh"
            w="4vh"
            className="filledStar"
            onClick={() => {
              setTimeout(() => setRating(2), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            cursor="pointer"
            src={ratingStarIcon}
          />
        )}
        {rating >= 3 ? (
          <BsFillStarFill
            cursor="pointer"
            className="emptyStar"
            onClick={() => {
              setTimeout(() => setRating(3), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            fill="#F7B500"
            size="34px"
          />
        ) : (
          <Image
            h="4vh"
            w="4vh"
            className="filledStar"
            onClick={() => {
              setTimeout(() => setRating(3), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            cursor="pointer"
            src={ratingStarIcon}
          />
        )}
        {rating >= 4 ? (
          <BsFillStarFill
            cursor="pointer"
            className="emptyStar"
            onClick={() => {
              setTimeout(() => setRating(4), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            fill="#F7B500"
            size="34px"
          />
        ) : (
          <Image
            h="4vh"
            w="4vh"
            className="filledStar"
            onClick={() => {
              setTimeout(() => setRating(4), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            cursor="pointer"
            src={ratingStarIcon}
          />
        )}
        {rating >= 5 ? (
          <BsFillStarFill
            cursor="pointer"
            className="emptyStar"
            onClick={() => {
              setTimeout(() => setRating(5), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            fill="#F7B500"
            size="34px"
          />
        ) : (
          <Image
            h="4vh"
            w="4vh"
            className="filledStar"
            onClick={() => {
              setTimeout(() => setRating(5), 1050)
              setFade(1)
            }}
            onAnimationEnd={() => setFade(0)}
            fade={fade}
            cursor="pointer"
            src={ratingStarIcon}
          />
        )}
      </HStack>
      <ZiptiText mt="6vh" fontWeight="bold">
        Review
      </ZiptiText>
      <ZiptiText color="gray.400" fontSize="1.5vh">
        Anything else you'd like to add?
      </ZiptiText>
      <ZiptiTextarea
        mt="2vh"
        w="80vw"
        h="15vh"
        placeholder={stateObject.placeholderCopy}
        onChange={e => setReview(e.target.value)}
      />
      <ZiptiButton
        mt="10vh"
        onClick={() => {
          props.onClickSubmit()
          const payload = { review_score: rating, review }
          if (!!rating && !!props.reviewId) {
            apiClient.submitReview(props.reviewId, payload)
            props.setReviewed(true)
          }
        }}
      >
        Submit
      </ZiptiButton>
      <ZiptiText mt="2vh" fontSize="1.5vh" fontWeight="bold">
        Cancel
      </ZiptiText>
    </Container>
  )
}

export default AskReview
