import React from "react"
import { Container } from "@chakra-ui/react"
import ZiptiText from "../zipti_text"

export interface VideoPlayerProps {
  videoSrc: string | undefined
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props: VideoPlayerProps) => {
  return (
    <Container
      centerContent
      position="relative"
      paddingInlineStart="0rem"
      paddingInlineEnd="0rem"
      bgColor="white"
    >
      <video
        // @ts-ignore
        display="block"
        position="absolute"
        top="0"
        height="100%"
        width="100%"
        autoPlay
        playsInline
        loop
        controls
        src={props.videoSrc}
      />
      <ZiptiText
        position="absolute"
        top="82vh"
        color="#4329AE"
        fontSize="1.75vh"
      ></ZiptiText>
    </Container>
  )
}

export default VideoPlayer
