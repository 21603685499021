import React, { useState } from "react"
import { COLORS } from "../shared/zipti_colors"
import ZiptiText from "../shared/zipti_text"
import AnswerMessage from "../shared/conversation/sent_message"
import AskMessage from "../shared/conversation/received_message"
import IconEvent from "../shared/conversation/icon_event"
import ButtonController from "./button_controller"
import ZiptiButton from "../shared/zipti_button"
import Wrapper from "../shared/wrapper"
import AskLogin from "../shared/ask/ask_login"
import AskPayment from "../shared/ask/ask_payment"
import AskLoading from "../shared/ask/ask_loading"
import AskSuccess from "../shared/ask/ask_success"
import { useDisclosure } from "@chakra-ui/hooks"
import VideoPreviewModal from "../shared/modal/video_preview_modal"
import ZiptiModal from "../shared/zipti_modal"
import VideoPromptModal from "../shared/modal/video_prompt_modal"
import ZiptiDrawer from "../shared/zipti_drawer"
import AskReview from "../shared/ask/ask_review"
import { Container } from "@chakra-ui/layout"
import { useEffect } from "react"
import {
  getTimeSince,
  getIdFromUrl,
  checkUploadSpeed,
} from "../../services/utils"
import { Image } from "@chakra-ui/image"
import { useContext } from "react"
import { UserContext, ConversationsContext } from "../user_wrapper"
import AskEventsWrapper from "../../components/shared/wrapper/askEventsWrapper"
import LinkEvent from "../shared/conversation/link_event"
import DescriptionEvent from "../shared/conversation/description_event"
import VideoPlayer from "../shared/modal/video_play_modal"
import { ApiContext } from "../user_wrapper"
import "moment-timezone"
import moment from "moment"
import { ConversationsResponse, FileUploadType } from "../../services/api_types"
import { uploadFile } from "../../services/api_utils"
import ClarifyingQuestion from "../shared/conversation/clarifying_question"
import ClarifyingAnswer from "../shared/conversation/clarifying_answer"
import { Box, Divider, HStack, Spinner, VStack } from "@chakra-ui/react"
import declineIcon from "../../images/declineIcon.svg"
import clarifyIcon from "../../images/clarifyIcon.svg"
import { navigate } from "gatsby"
import Answer from "../answer"
import ReviewEvent from "../shared/conversation/review_event"
import ShortAnswer from "../answer/click_in_answer"

interface UserConversationProps {
  profilePhotoSrc: string
  videoThumbnailSrc: string
  videoFile: File
}

enum ASK_STAGES {
  PREVIEW = "preview",
  LOGIN = "login",
  PAYMENT = "payment",
  LOADING = "loading",
  SUCCESS = "success",
}
enum DRAWER_TYPES {
  ASK = "ask",
  REVIEW = "review",
  WATCH = "watch",
  CLARIFYING_ANSWER = "clarifying_answer",
  CLARIFYING_QUESTION = "clarifying_question",
  ANSWER_DECISION = "answer_decision",
  ANSWER = "answer",
  DEFAULT = "default",
  SHORT_ANSWER = "short_answer",
}

enum MODAL_TYPES {
  DEFAULT = "default",
  CLARIFYING_ANSWER = "clarifying_answer",
  CLARIFYING_QUESTION = "clarifying_question",
  REDO = "redo",
  ANSWER_DECISION = "answer_decision",
  ANSWER = "answer",
}

const UserConversation: React.FC<UserConversationProps> = props => {
  const user = useContext(UserContext)
  const apiClient = useContext(ApiContext)
  const [toggleSpinner, setToggleSpinner] = useState<boolean>(false)
  const [profilePrice, setProfilePrice] = useState<number | undefined>(
    undefined
  )
  const [profileName, setProfileName] = useState<string | undefined>(undefined)
  const [profilePhotoSrc, setProfilePhotoSrc] = useState<string | undefined>(
    undefined
  )
  const [accountPhotoSrc, setAccountPhotoSrc] = useState<string | undefined>(
    undefined
  )
  const [askId, setAskId] = useState(undefined)
  const [reviewed, setReviewed] = useState<boolean>(false)
  const [timeSince, setTimeSince] = useState<string | undefined>(undefined)
  const timezone = moment.tz.guess()
  const [reviewId, setReviewId] = useState<string | undefined>(undefined)
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined)
  const [askStage, setAskStage] = useState(ASK_STAGES.PREVIEW)
  const [drawerType, setDrawerType] = useState(DRAWER_TYPES.ASK)
  const [modalType, setModalType] = useState(MODAL_TYPES.DEFAULT)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingFileSize, setLoadingFileSize] = useState<number>(0)
  const [previousModalType, setPreviousModalType] = useState(
    MODAL_TYPES.DEFAULT
  )
  const [networkSpeed, setNetworkSpeed] = useState(3500000)
  const [conversations, setConversations] = useState<
    ConversationsResponse | undefined
  >(undefined)
  const [conversationsList, setConversationsList] = useState(undefined)
  const onDrawerOpen = () => {
    setIsDrawerOpen(true)
  }
  const onDrawerClose = () => {
    setIsDrawerOpen(false)
  }
  const [isAsker, setIsAsker] = useState(false)

  const handleVideoSubmission = async () => {
    if (modalType === MODAL_TYPES.CLARIFYING_ANSWER) {
      const res = await apiClient.submitClarifyingAnswerVideo(
        { video_url: videoSrc },
        askId
      )
      onDrawerClose()
      console.log("clairfyRes", res)
    } else if (modalType === MODAL_TYPES.CLARIFYING_QUESTION) {
      const res = await apiClient.submitClarifyingQuestionVideo(
        { video_url: videoSrc },
        askId
      )
      onDrawerClose()
      console.log("clairfyRes", res)
    } else if (
      modalType === MODAL_TYPES.REDO &&
      previousModalType === MODAL_TYPES.CLARIFYING_ANSWER
    ) {
      const res = await apiClient.submitClarifyingAnswerVideo(
        { video_url: videoSrc },
        askId
      )
      onDrawerClose()
      console.log("clairfyRes", res)
    } else if (
      modalType === MODAL_TYPES.REDO &&
      previousModalType === MODAL_TYPES.CLARIFYING_QUESTION
    ) {
      const res = await apiClient.submitClarifyingQuestionVideo(
        { video_url: videoSrc },
        askId
      )
      onDrawerClose()
      console.log("clairfyRes", res)
    } else if (
      modalType === MODAL_TYPES.ANSWER_DECISION ||
      previousModalType === MODAL_TYPES.ANSWER_DECISION
    ) {
      setDrawerType(DRAWER_TYPES.ANSWER)
      onDrawerOpen()
    }
  }

  const handleLogin = () => {
    setAskStage(ASK_STAGES.PAYMENT)
  }

  const handlePayment = () => {
    setAskStage(ASK_STAGES.LOADING)
  }

  const handleFinishLoading = () => {
    setAskStage(ASK_STAGES.SUCCESS)
  }

  const handleFinishAsk = () => {
    setVideoSrc(undefined)
    onDrawerClose()
    setAskStage(ASK_STAGES.PREVIEW)
  }

  const handleRedoAsk = () => {
    setVideoSrc(undefined)
    setModalType(MODAL_TYPES.REDO)
    onOpen()
    onDrawerClose()
  }

  const handleAskVideoFile = async (videoFile: File) => {
    setLoadingFileSize(videoFile.size)
    setIsLoading(true)
    setVideoSrc(undefined)
    setDrawerType(DRAWER_TYPES.ASK)
    onClose()
    onDrawerOpen()
    let fileType
    if (modalType === MODAL_TYPES.CLARIFYING_QUESTION) {
      console.log(modalType, previousModalType)
      fileType = FileUploadType.SUBMIT_CLARIFYING_QUESTION_VIDEO
    } else if (modalType === MODAL_TYPES.CLARIFYING_ANSWER) {
      console.log(modalType, previousModalType)
      fileType = FileUploadType.SUBMIT_CLARIFYING_ANSWER_VIDEO
    } else if (
      modalType === MODAL_TYPES.REDO &&
      previousModalType === MODAL_TYPES.CLARIFYING_QUESTION
    ) {
      console.log(modalType, previousModalType)
      fileType = FileUploadType.SUBMIT_CLARIFYING_QUESTION_VIDEO
    } else if (
      modalType === MODAL_TYPES.REDO &&
      previousModalType === MODAL_TYPES.CLARIFYING_ANSWER
    ) {
      console.log(modalType, previousModalType)
      fileType = FileUploadType.SUBMIT_CLARIFYING_ANSWER_VIDEO
    } else if (
      modalType === MODAL_TYPES.ANSWER_DECISION ||
      modalType === MODAL_TYPES.REDO
    ) {
      console.log(modalType, previousModalType)
      fileType = FileUploadType.SUBMIT_ANSWER_VIDEO
    }

    if (!fileType) return

    const video_url = await uploadFile(apiClient, videoFile, fileType, askId)

    if (modalType !== MODAL_TYPES.REDO) {
      setPreviousModalType(modalType)
    }
    if (modalType === MODAL_TYPES.ANSWER) {
      setDrawerType(DRAWER_TYPES.ANSWER)
    }
    setVideoSrc(video_url)

    setIsLoading(false)
  }

  const handleDecline = async () => {
    if (!askId) {
      return
    }
    const result = await apiClient.declineAsk(askId)
    if (result.error) {
      console.log("issue declining ask", result.error)
    }
    navigate("/home")
  }

  const openVideo = (askVideoSrc: string) => {
    setDrawerType(DRAWER_TYPES.WATCH)
    onDrawerOpen()
  }

  const openReviewDrawer = review => {
    setReviewId(review)
    setDrawerType(DRAWER_TYPES.REVIEW)
    onDrawerOpen()
  }

  const openAskModal = () => {
    setDrawerType(DRAWER_TYPES.ASK)
    onOpen()
  }

  // Needs to be built
  const onSuccess = () => {
    console.log("Success!")
  }

  useEffect(() => {
    const conversationUseEffect = async () => {
      setToggleSpinner(true)
      const res = await apiClient.networkSpeedTest()
      setNetworkSpeed(res.data?.mbps)
      const conversationsResponse = await apiClient.getConversations()
      if (user.data?.account?.is_expert) {
        const profileData = await apiClient.getProfile()
        setProfileIdForExpert(profileData.data?.profile_id)
      }

      setConversations(conversationsResponse.data)

      const conversations = getConversationFromContext(
        conversationsResponse.data,
        profileId
      )

      conversations.asks.sort((a, b) => {
        return a.updated_at > b.updated_at ? 1 : -1
      })
      setConversationsList(conversations)
      if (conversations.asks[conversations.asks.length - 1]) {
        setTimeSince(
          moment
            .utc(conversations.asks[conversations.asks.length - 1].updated_at)
            .tz(timezone)
            .fromNow()
        )
      }
      if (
        conversations.asks[conversations.asks.length - 1].profile_id ==
        getIdFromUrl("conversation")
      ) {
        setIsAsker(true)
      } else {
        setIsAsker(false)
      }
      setToggleSpinner(false)
      getProfileNameInfo()
      window.scrollTo(0, 9999999)
    }
    conversationUseEffect()
  }, [user.data?.account, isDrawerOpen])

  const getConversationFromContext = (conversation, id) => {
    const defaultAsk = {
      asks: [],
    }
    if (!conversation) {
      return defaultAsk
    }
    if (!!conversation.account_conversations[id]) {
      return conversation.account_conversations[id]
    }
    if (!!conversation.profile_conversations[id]) {
      return conversation.profile_conversations[id]
    }
    return defaultAsk
  }

  const profileId = getIdFromUrl("conversation")
  const [profileIdForExpert, setProfileIdForExpert] = useState("")
  // setProfileName(conversation.account.account_name)
  // setProfilePhotoSrc(conversation.account.profile_image_url)
  //setTimeSince(getTimeSince(conversation.time_since))

  const openClarifyingAnswerDrawer = () => {
    setDrawerType(DRAWER_TYPES.CLARIFYING_ANSWER)
    onDrawerOpen()
  }

  const openClarifyingQuestionDrawer = () => {
    setDrawerType(DRAWER_TYPES.CLARIFYING_QUESTION)
    onDrawerOpen()
  }

  const openAnswerDecisionDrawer = () => {
    setDrawerType(DRAWER_TYPES.ANSWER_DECISION)
    onDrawerOpen()
  }

  const openDefaultDrawer = () => {
    setDrawerType(DRAWER_TYPES.DEFAULT)
    onDrawerOpen()
  }

  const openCurrentModal = type => {
    setModalType(type)
    onOpen()
  }

  const getProfileNameInfo = async () => {
    const id = getIdFromUrl("conversation")
    const accountInfo = await apiClient.getAccountById(id)
    console.log("USER", user)
    if (accountInfo.data?.account) {
      setProfileName(accountInfo.data?.account?.name)
      setProfilePhotoSrc(user.data?.account?.profile_image_url)
      setAccountPhotoSrc(accountInfo.data?.account?.profile_image_url)
      return
    } else {
      const profileInfo = await apiClient.getPublicProfile(id)
      setProfileName(profileInfo.data?.account.name)
      setProfilePhotoSrc(profileInfo.data?.account.profile_image_url)
      setProfilePrice(profileInfo.data?.profile.ask_price)
      setAccountPhotoSrc(user.data?.account?.profile_image_url)
    }
  }

  const renderConversations = () => {
    if (!conversationsList) return
    return conversationsList?.asks.map(ask => {
      ask.ask_events.sort((a, b) => {
        return a.created_at > b.created_at ? 1 : -1
      })

      const askEventsList = ask.ask_events.map(askEvent => {
        return (
          <AskEventsWrapper>
            {askEvent.event_name === "decline_ask" ? (
              <IconEvent
                mt="3vh"
                eventType="declined"
                eventCopy={`${profileName} has declined your question.`}
              />
            ) : null}
            {askEvent.event_name === "say_thanks" ? (
              <IconEvent
                mt="3vh"
                eventType="thanks"
                eventCopy={
                  user.data?.account?.is_expert && !isAsker
                    ? `${profileName} says thanks!`
                    : `You said thanks!`
                }
                eventDate={moment
                  .utc(askEvent.created_at)
                  .tz(timezone)
                  .fromNow()}
              />
            ) : null}
            {askEvent.event_name === "submit_review" ? (
              <ReviewEvent
                reviewRating={askEvent.payload.review_score}
                reviewComment={askEvent.payload.review}
                mt="3vh"
                eventCopy={
                  user.data?.account?.is_expert
                    ? // TODO: add !isAsker after merge of clarifying questions flow revamp...
                      `${profileName} gave you a review!`
                    : "Your review"
                }
                eventDate={moment
                  .utc(askEvent.created_at)
                  .tz(timezone)
                  .fromNow()}
              />
            ) : null}
            {askEvent.event_name === "submit_ask_video" ? (
              <AskMessage
                mt="3vh"
                videoThumbnailSrc={ask.account_ask_video_thumbnail_url}
                timeSinceCopy={moment
                  .utc(askEvent.created_at)
                  .tz(timezone)
                  .fromNow()}
                profilePhotoSrc={accountPhotoSrc}
                onVideoThumbnailClick={openAnswerDecisionDrawer}
                setVideoSrc={setVideoSrc}
                videoSrc={askEvent.payload.video_url}
                askId={ask.ask_id}
                askEventId={askEvent.ask_event_id}
                setAskId={setAskId}
                isAccount={user.data?.account?.is_expert}
              />
            ) : null}
            {askEvent.event_name === "submit_clarifying_question" ? (
              <ClarifyingQuestion
                mt="3vh"
                videoThumbnailSrc={ask.account_ask_video_thumbnail_url}
                timeSinceCopy={moment
                  .utc(askEvent.created_at)
                  .tz(timezone)
                  .fromNow()}
                profilePhotoSrc={profilePhotoSrc}
                onVideoThumbnailClick={openClarifyingAnswerDrawer}
                setVideoSrc={setVideoSrc}
                videoSrc={askEvent.payload.video_url}
                askId={ask.ask_id}
                setAskId={setAskId}
                askEventId={askEvent.ask_event_id}
                accountSeen={askEvent.account_seen_at}
                isExpert={user.data?.account?.is_expert}
                isAccount={user.data?.account?.is_expert}
                profileId={
                  user.data?.account?.is_expert ? profileIdForExpert : profileId
                }
              />
            ) : null}
            {askEvent.event_name === "submit_clarifying_answer" ? (
              <ClarifyingAnswer
                mt="3vh"
                videoThumbnailSrc={ask.account_ask_video_thumbnail_url}
                profilePhotoSrc={accountPhotoSrc}
                timeSinceCopy={moment
                  .utc(askEvent.created_at)
                  .tz(timezone)
                  .fromNow()}
                onVideoThumbnailClick={openClarifyingQuestionDrawer}
                setVideoSrc={setVideoSrc}
                videoSrc={askEvent.payload.video_url}
                askId={ask.ask_id}
                setAskId={setAskId}
                askEventId={askEvent.ask_event_id}
                expertSeen={askEvent.expert_seen_at}
                isExpert={user.data?.account?.is_expert}
                isAccount={user.data?.account?.is_expert}
              />
            ) : null}
            {askEvent.event_name === "submit_answer_video" ? (
              <AnswerMessage
                mt="3vh"
                videoThumbnailSrc={ask.account_ask_video_thumbnail_url}
                profilePhotoSrc={profilePhotoSrc}
                timeSinceCopy={moment
                  .utc(askEvent.created_at)
                  .tz(timezone)
                  .fromNow()}
                onVideoThumbnailClick={openDefaultDrawer}
                setVideoSrc={setVideoSrc}
                videoSrc={askEvent.payload.video_url}
                askId={ask.ask_id}
                askEventId={askEvent.ask_event_id}
                setAskId={setAskId}
                isAccount={user.data?.account?.is_expert}
                profileId={
                  user.data?.account?.is_expert ? profileIdForExpert : profileId
                }
              />
            ) : null}
            {askEvent.event_name === "attach_links" ? (
              <LinkEvent
                eventLinks={askEvent.payload.attachments}
                eventDate={moment
                  .utc(askEvent.created_at)
                  .tz(timezone)
                  .fromNow()}
                isAccount={user.data?.account?.is_expert}
              />
            ) : null}
            {askEvent.event_name === "submit_expert_ask_description" ? (
              <DescriptionEvent
                eventDescriptions={askEvent.payload.expert_ask_description}
                eventDate={moment
                  .utc(askEvent.created_at)
                  .tz(timezone)
                  .fromNow()}
                isAccount={user.data?.account?.is_expert}
              />
            ) : null}
          </AskEventsWrapper>
        )
      })

      return (
        <Box>
          {askEventsList.length ? askEventsList : null}

          {user.data?.account?.is_expert ? null : (
            <ButtonController
              mt="5vh"
              setReviewId={setReviewId}
              askId={ask.ask_id}
              onClickReview={openReviewDrawer}
              setReviewed={setReviewed}
              reviewed={reviewed}
            />
          )}
          <VStack>
            {user.data?.account?.is_expert ? (
              <ZiptiButton
                mb="1rem"
                id={ask.ask_id}
                onClick={e => {
                  setDrawerType(DRAWER_TYPES.SHORT_ANSWER)
                  setAskId(e.target.id)
                  onDrawerOpen()
                }}
              >
                Add Attachments
              </ZiptiButton>
            ) : null}
          </VStack>
          <Divider borderTopWidth="0.25vh" />
        </Box>
      )
    })
  }

  const renderModal = () => {
    switch (modalType) {
      case MODAL_TYPES.DEFAULT:
        return (
          <VideoPromptModal
            promptText={`Yay! You're ready to ask ${profileName} a question. If the video is longer than 5 minutes you will not be able to send it.`}
            buttonText="Ask"
            handleVideoFile={handleAskVideoFile}
          />
        )
      case MODAL_TYPES.ANSWER_DECISION:
        return (
          <VideoPromptModal
            promptText={`Answer this question?`}
            buttonText="Answer"
            handleVideoFile={handleAskVideoFile}
          />
        )
      case MODAL_TYPES.CLARIFYING_QUESTION:
        return (
          <VideoPromptModal
            promptText={`Would you like to ask a clarifying question?`}
            buttonText="Clarify"
            handleVideoFile={handleAskVideoFile}
          />
        )
      case MODAL_TYPES.CLARIFYING_ANSWER:
        return (
          <VideoPromptModal
            promptText={`Respond with a clarifying answer?`}
            buttonText="Respond"
            handleVideoFile={handleAskVideoFile}
          />
        )
      case MODAL_TYPES.REDO:
        return (
          <VideoPromptModal
            promptText={`Redo your video?`}
            buttonText="Redo"
            handleVideoFile={handleAskVideoFile}
          />
        )
    }
  }

  const renderDrawer = () => {
    switch (drawerType) {
      case DRAWER_TYPES.ASK:
        return renderAsk()
      case DRAWER_TYPES.REVIEW:
        return (
          <AskReview
            reviewId={reviewId}
            profileName={profileName}
            onClickSubmit={onDrawerClose}
            setReviewed={setReviewed}
          />
        )
      case DRAWER_TYPES.CLARIFYING_ANSWER:
        return (
          <Container centerContent>
            <VideoPlayer videoSrc={videoSrc} />
            {user.data?.account?.is_expert && !isAsker ? null : (
              <>
                <ZiptiText
                  marginInline="1rem"
                  position="absolute"
                  top="67vh"
                  fontSize="2.5vh"
                >
                  It looks like you've been asked for some clarification!
                </ZiptiText>
                <Box
                  paddingInline={{ base: 0 }}
                  position="fixed"
                  bottom="0px"
                  w="100%"
                  h="10vh"
                  bgColor="white"
                >
                  <Divider borderTopWidth="0.25vh" />
                  <HStack
                    bgColor={COLORS.BLUE}
                    paddingInline={{ base: "2rem", md: "25%" }}
                    justifyContent="center"
                    w="100%"
                    h="100%"
                  >
                    <VStack>
                      <ZiptiButton
                        onClick={() =>
                          openCurrentModal(MODAL_TYPES.CLARIFYING_ANSWER)
                        }
                      >
                        Respond
                      </ZiptiButton>{" "}
                    </VStack>
                  </HStack>
                </Box>
              </>
            )}
          </Container>
        )
      case DRAWER_TYPES.DEFAULT:
        return (
          <Container centerContent>
            <VideoPlayer videoSrc={videoSrc} />
          </Container>
        )
      case DRAWER_TYPES.CLARIFYING_QUESTION:
        return (
          <Container centerContent>
            <VideoPlayer videoSrc={videoSrc} />
            {user.data?.account?.is_expert && !isAsker ? (
              <>
                <ZiptiText
                  marginInline="1rem"
                  position="absolute"
                  top="67vh"
                  fontSize="2.5vh"
                >
                  Did you need to clarify anything further?
                </ZiptiText>
                <Box
                  paddingInline={{ base: 0 }}
                  position="fixed"
                  bottom="0px"
                  w="100%"
                  h="10vh"
                  bgColor="white"
                >
                  <Divider borderTopWidth="0.25vh" />
                  <HStack
                    bgColor={COLORS.BLUE}
                    paddingInline={{ base: "2rem", md: "25%" }}
                    justifyContent="space-evenly"
                    w="100%"
                    h="100%"
                  >
                    <ZiptiButton
                      onClick={() =>
                        openCurrentModal(MODAL_TYPES.CLARIFYING_QUESTION)
                      }
                    >
                      {" "}
                      Clarify
                    </ZiptiButton>
                    <ZiptiButton
                      onClick={() =>
                        openCurrentModal(MODAL_TYPES.ANSWER_DECISION)
                      }
                    >
                      Answer
                    </ZiptiButton>
                  </HStack>
                </Box>
              </>
            ) : null}
          </Container>
        )
      case DRAWER_TYPES.SHORT_ANSWER:
        return (
          <ShortAnswer
            name={profileName}
            askerProfilePicSrc={accountPhotoSrc}
            timeSince={timeSince}
            askId={askId}
            videoSrc={videoSrc}
            setVideoSrc={setVideoSrc}
            onDrawerClose={onDrawerClose}
          />
        )
      case DRAWER_TYPES.ANSWER:
        return (
          <Answer
            name={profileName}
            askerProfilePicSrc={accountPhotoSrc}
            timeSince={timeSince}
            askId={askId}
            videoSrc={videoSrc}
            setVideoSrc={setVideoSrc}
            onDrawerClose={onDrawerClose}
          />
        )
      case DRAWER_TYPES.ANSWER_DECISION:
        return (
          <Container centerContent>
            <VideoPlayer videoSrc={videoSrc} />
            {user.data?.account?.is_expert && !isAsker ? (
              <>
                {" "}
                <Box
                  paddingInline={{ base: 0 }}
                  position="fixed"
                  bottom="0px"
                  w="100%"
                  h="10vh"
                  bgColor="white"
                >
                  <Divider borderTopWidth="0.25vh" />
                  <HStack
                    bgColor={COLORS.BLUE}
                    paddingInline={{ base: "2rem", md: "25%" }}
                    justifyContent="space-between"
                    w="100%"
                    h="100%"
                  >
                    <VStack>
                      {" "}
                      <Image
                        src={declineIcon}
                        h="6vh"
                        w="6vh"
                        cursor="pointer"
                        onClick={handleDecline}
                      />
                      <ZiptiText
                        mt="-2rem"
                        fontWeight="bold"
                        fontSize="1vh"
                        color="gray.400"
                      >
                        Decline
                      </ZiptiText>
                    </VStack>
                    <ZiptiButton
                      onClick={() =>
                        openCurrentModal(MODAL_TYPES.ANSWER_DECISION)
                      }
                    >
                      Answer
                    </ZiptiButton>
                    <VStack>
                      <Image
                        src={clarifyIcon}
                        h="6vh"
                        w="6vh"
                        cursor="pointer"
                        onClick={() =>
                          openCurrentModal(MODAL_TYPES.CLARIFYING_QUESTION)
                        }
                      />
                      <ZiptiText
                        fontWeight="bold"
                        fontSize="1vh"
                        color="gray.400"
                      >
                        Clarify
                      </ZiptiText>
                    </VStack>
                  </HStack>
                </Box>
              </>
            ) : null}
          </Container>
        )
    }
  }

  const renderAsk = () => {
    switch (askStage) {
      case ASK_STAGES.PREVIEW:
        return (
          <VideoPreviewModal
            videoSrc={videoSrc}
            headerCopy={profileName}
            detailCopy={"Click submit to send your video"}
            buttonCopy={"Submit"}
            footerCopy={"Redo"}
            onClickFinish={handleVideoSubmission}
            onClickRedo={handleRedoAsk}
            isLoading={isLoading}
            loadingFileSize={loadingFileSize}
            networkSpeed={networkSpeed}
          />
        )
      case ASK_STAGES.LOGIN:
        return <AskLogin handleLogin={handleLogin} />
      case ASK_STAGES.PAYMENT:
        return (
          <AskPayment onSuccess={onSuccess} handlePayment={handlePayment} />
        )
      case ASK_STAGES.LOADING:
        return <AskLoading finishLoading={handleFinishLoading} />
      case ASK_STAGES.SUCCESS:
        return <AskSuccess finishAsk={handleFinishAsk} />
    }
  }

  return (
    <Wrapper>
      <ZiptiText mb="5vh" mt="2rem" fontWeight="bold">
        {profileName}
      </ZiptiText>
      {renderConversations()}
      {toggleSpinner ? (
        <Spinner position="fixed" top="48%" left="48%" color={COLORS.PURPLE} />
      ) : null}
      {user.data?.account?.is_expert ? null : (
        <Box
          paddingInline={{ base: 0 }}
          position="fixed"
          bottom="0px"
          w="100%"
          h="10vh"
          bgColor="white"
        >
          <Divider w="100%" borderTopWidth="0.25vh" />
          <HStack display="flex" justifyContent="center" w="100%" h="100%">
            <ZiptiButton
              h="6vh"
              w={{ base: "85%", md: "50%" }}
              borderRadius="10px"
              fontSize="1.5vh"
              onClick={openAskModal}
            >
              Ask {profileName} ${profilePrice}
            </ZiptiButton>
          </HStack>
        </Box>
      )}
      <ZiptiModal isOpen={isOpen} onClose={onClose} renderModal={renderModal} />
      <ZiptiDrawer
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
        renderDrawer={renderDrawer}
      />
    </Wrapper>
  )
}

export default UserConversation
