import React, { useContext, useState, useEffect } from "react"
import { Box, Center } from "@chakra-ui/react"
import { ApiContext, UserContext, ConversationsContext } from "../user_wrapper"
import Wrapper from "../shared/wrapper"
import {
  getIdFromUrl,
  getTimeSince,
  findLatestAskEvent,
  findAskEventsByEventName,
} from "../../services/utils"
import AskerCard from "./asker_card"
import RecordCard from "./record_card"
import AttachCard from "./attach_card"
import ExpandCard from "./expand_card"
import ZiptiButton from "../shared/zipti_button"
import { navigate } from "gatsby-link"
import ZiptiText from "../shared/zipti_text"
import ZiptiDrawer from "../shared/zipti_drawer"
import { uploadFile } from "../../services/api_utils"
import { FileUploadType } from "../../services/api_types"
import VideoPromptModal from "../shared/modal/video_prompt_modal"
import ZiptiModal from "../shared/zipti_modal"
import { useDisclosure } from "@chakra-ui/hooks"
import VideoPreviewModal from "../shared/modal/video_preview_modal"
import {
  AskAccountInfo,
  AskEvent,
  Attachments,
  GetAskResponse,
  SubmitExpertAskDescriptionWrapper,
} from "../../services/api_types"

interface ShortAnswerProps {
  name: string
  askerProfilePicSrc: string | undefined
  timeSinceAsked: string
  askId: string
  videoSrc: string
  setVideoSrc: Function
  videoThumbnailSrc: string | undefined
  onDrawerClose: Function
  recordCopy: string
  attachCopy: string
  descriptionPlaceholder: string
  linkPlaceHolder: Object
  expandCopy: string
  timeSince: Object
  ask_events: Array<Object>
  created_at: string
}

const ShortAnswer: React.FC<ShortAnswerProps> = props => {
  const apiClient = useContext(ApiContext)
  const conversations = useContext(ConversationsContext)
  const [askRes, setAskRes] = useState<GetAskResponse | undefined>(undefined)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [videoSrc, setVideoSrc] = useState<string | undefined>("")
  const [askInfoRes, setAskInfoRes] = useState<AskAccountInfo | undefined>(
    undefined
  )
  const [attachments, setAttachments] = useState<Attachments | undefined>(
    undefined
  )
  const [expertAskDescription, setExpertAskDescription] = useState<
    SubmitExpertAskDescriptionWrapper | undefined
  >(undefined)
  const [originalAttachments, setOriginalAttachments] = useState<
    Object | undefined
  >(undefined)
  const [
    originalExpertAskDescription,
    setOriginalExpertAskDescription,
  ] = useState<string | undefined>(undefined)
  const [toggleIsNotValid, setToggleIsNotValid] = useState({
    link_1: true,
    link_2: true,
    link_3: true,
  })

  const onDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const answerUseEffect = async () => {
    const askId = getIdFromUrl("answer")
    if (!askId) {
      return
    }
    const res = await apiClient.getAsk(askId)
    if (!!res.error || !res.data) {
      return
    }
    setAskRes(res.data)

    const latestAttachLinks = findLatestAskEvent(
      findAskEventsByEventName(res.data.ask.ask_events, "attach_links")
    )
    const latestExpertDescription = findLatestAskEvent(
      findAskEventsByEventName(
        res.data.ask.ask_events,
        "submit_expert_ask_description"
      )
    )

    if (latestAttachLinks)
      setOriginalAttachments(latestAttachLinks.payload.attachments)
    if (latestExpertDescription)
      setOriginalExpertAskDescription(
        latestExpertDescription.payload.expert_ask_description
      )
    const resAccountInfo = await apiClient.getAccountById(
      res.data.ask.account_id
    )
    console.log("RESPONSE", resAccountInfo)

    if (!!resAccountInfo.error || !resAccountInfo.data) {
      console.log("issue fetching account information")
      console.log(resAccountInfo.error)
      console.log(resAccountInfo.data)
      return
    }

    setAskInfoRes(resAccountInfo.data)
  }

  const onDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  useEffect(() => {
    answerUseEffect()
  }, [])

  const handleSubmitAnswer = async () => {
    if (
      Object.values(toggleIsNotValid).filter(link => {
        return link
      }).length !== 3
    ) {
      return
    }

    if (!props.askId) {
      return
    }

    if (attachments && attachments !== originalAttachments) {
      const attachLinksRes = await apiClient.submitAttachLinks(
        props.askId,
        attachments
      )
      console.log("RESPONSE FROM submitAttachedLinks:", attachLinksRes)
    }

    if (
      expertAskDescription &&
      expertAskDescription.expert_ask_description !==
        originalExpertAskDescription
    ) {
      const expertAskDescriptionRes = await apiClient.submitExpertAskDescription(
        props.askId,
        expertAskDescription
      )
      console.log(
        "RESPONSE FROM submitExpertDescription:",
        expertAskDescriptionRes
      )
    }
    await conversations.resetConversations()

    props.onDrawerClose()
  }

  return (
    <Box pt="1vh" pb="1vh" h="240vh">
      <AskerCard
        name={props.name}
        askerProfilePicSrc={props.askerProfilePicSrc}
        timeSinceAsked={props.timeSince}
      />
      <AttachCard
        toggleIsNotValid={toggleIsNotValid}
        setToggleIsNotValid={setToggleIsNotValid}
        attachments={attachments}
        setAttachments={setAttachments}
        linkPlaceholder={originalAttachments}
        descriptionPlaceholder={originalAttachments}
        attachCopy="You can attach any links you'd like to share here."
      />
      <ExpandCard
        expertAskDescription={expertAskDescription}
        setExpertAskDescription={setExpertAskDescription}
        expandCopy="Have anything else you'd like to say? Add any other details about your response here!"
        linkPlaceholder={originalExpertAskDescription}
        descriptionPlaceholder="Please add a description for this link"
      />
      <Center mt="10vh">
        <ZiptiButton onClick={handleSubmitAnswer}>Submit</ZiptiButton>
      </Center>
      <ZiptiText onClick={props.onDrawerClose} mt="2vh" cursor="pointer">
        Cancel
      </ZiptiText>
    </Box>
  )
}
export default ShortAnswer
