import { Box, HStack } from "@chakra-ui/layout"
import React from "react"
import ZiptiText from "../zipti_text"
import AttachmentIcon from "../../../images/attachmentIcon.svg"
import ZiptiContainer from "../zipti_container"

interface DescriptionEventProps {
  eventDescriptions: string | undefined
  eventDate: string
  isAccount: boolean
}

const DescriptionEvent: React.FC<DescriptionEventProps> = props => {
  return (
    <Box
      mb="2rem"
      mt="2rem"
      w={{ base: "68%", md: "45%" }}
      left={
        !props.isAccount ? { base: "3%", md: "3%" } : { base: "30%", md: "52%" }
      }
      position="relative"
    >
      <HStack
        color="purple"
        fontSize="1.5vh"
        position="relative"
        backgroundColor="white.300"
        borderRadius="8px"
        boxShadow="md"
        mb="1rem"
        p="1rem"
      >
        <ZiptiText textAlign="left">{props.eventDescriptions}</ZiptiText>
      </HStack>
      <ZiptiText color="gray.400" fontSize="1.5vh">
        {props.eventDate}
      </ZiptiText>
    </Box>
  )
}

export default DescriptionEvent
