import { Avatar } from "@chakra-ui/avatar"
import { Image } from "@chakra-ui/image"
import { Box } from "@chakra-ui/layout"
import React, { useContext } from "react"
import { useState } from "react"
import { navigate } from "gatsby"
import playTriangleIcon from "../../../images/playTriangle.svg"
import { ApiContext } from "../../user_wrapper"
import ZiptiText from "../zipti_text"
import ZiptiButton from "../zipti_button"

interface ClarifyingQuestionProps {
  timeSinceCopy: string | undefined
  profilePhotoSrc: string | undefined
  videoThumbnailSrc: string
  mt: string
  onVideoThumbnailClick: Function
  videoSrc: string
  setVideoSrc: Function
  askId: string
  setAskId: Function
  isExpert: boolean
  accountSeen: boolean
  askEventId: string
  isAccount: boolean
}

const ClarifyingQuestion: React.FC<ClarifyingQuestionProps> = props => {
  const apiClient = useContext(ApiContext)
  const [seen, setSeen] = useState(props.accountSeen ? false : true)
  const handleClickAllowed = async () => {
    await apiClient.submit_account_seen_at(props.askEventId, props.askId)
    props.setAskId(props.askId)
    props.setVideoSrc(props.videoSrc)
    props.onVideoThumbnailClick()
    setSeen(false)
  }
  const handleClickDenied = async () => {
    if (props.isExpert) {
      await apiClient.submit_account_seen_at(props.askEventId, props.askId)
      props.setAskId(props.askId)
    }
    props.setVideoSrc(props.videoSrc)
    props.onVideoThumbnailClick()
  }

  const renderClarifyingQuestionExpert = () => {
    return (
      <Box
        mt={!!props.mt ? props.mt : 0}
        w="100vw"
        h={{ base: "40vh", md: "55vh" }}
        position="relative"
      >
        <Avatar
          position="absolute"
          left="5vw"
          h={{ base: "5vh", md: "10vh" }}
          w={{ base: "10vw", md: "7vw" }}
          boxShadow="0 2px 10px 0 rgba(0,0,0,0,20)"
          borderRadius="100px 100px 1px 100px"
          // borderRadius="md"
          onClick={() => navigate(`/profile/${props.profileId}`)}
          src={props.profilePhotoSrc}
        />
        <Image
          position="absolute"
          left="17vw"
          h={{ base: "30vh", md: "50vh" }}
          w={{ base: "40vw", md: "20vw" }}
          borderRadius="md"
          src={props.videoThumbnailSrc}
          cursor="pointer"
          zIndex="0"
          onClick={props.isExpert ? handleClickDenied : handleClickAllowed}
        />
        <Image
          position="absolute"
          top={{ base: "25vh", md: "45vh" }}
          left={{ base: "22vw", md: "26.5vw" }}
          w="3vh"
          h="3vh"
          src={playTriangleIcon}
        />
        {seen ? (
          <ZiptiText
            position="absolute"
            top={{ base: "1rem", md: "8rem" }}
            left={{ base: "17vw", md: "7.5vw" }}
            color="red.200"
            w="40vw"
          >
            {props.isExpert ? null : "Response Requested"}
          </ZiptiText>
        ) : null}
        {seen && !props.isExpert ? (
          <ZiptiButton
            position="absolute"
            left={{ base: "16rem", md: "36rem" }}
            borderRadius="100%"
            w="3rem"
            h="3rem"
            onClick={props.isExpert ? handleClickDenied : handleClickAllowed}
          >
            Clarify
          </ZiptiButton>
        ) : null}

        <ZiptiText
          position="absolute"
          fontSize="1.5vh"
          top={{ base: "31vh", md: "52vh" }}
          left={{ base: "22vw", md: "12.5vw" }}
          w="30vw"
          color="gray.400"
        >
          {props.timeSinceCopy}
        </ZiptiText>
      </Box>
    )
  }

  const renderClarifyingQuestionAccount = () => {
    return (
      <Box
        mt={!!props.mt ? props.mt : 0}
        w="100vw"
        h={{ base: "40vh", md: "55vh" }}
        position="relative"
      >
        <Avatar
          position="absolute"
          left={{ base: "85vw", md: "87.5vw" }}
          h={{ base: "5vh", md: "10vh" }}
          w={{ base: "10vw", md: "7vw" }}
          boxShadow="0 2px 10px 0 rgba(0,0,0,0,20)"
          borderRadius="100px 100px 100px 1px"
          // borderRadius="md"
          onClick={() => navigate(`/profile/${props.profileId}`)}
          src={props.profilePhotoSrc}
        />
        <Image
          position="absolute"
          left={{ base: "43vw", md: "62vw" }}
          h={{ base: "30vh", md: "50vh" }}
          w={{ base: "40vw", md: "20vw" }}
          borderRadius="md"
          src={props.videoThumbnailSrc}
          cursor="pointer"
          zIndex="0"
          onClick={props.isExpert ? handleClickDenied : handleClickAllowed}
        />
        <Image
          position="absolute"
          top={{ base: "25vh", md: "45vh" }}
          left={{ base: "48vw", md: "71vw" }}
          w="3vh"
          h="3vh"
          src={playTriangleIcon}
        />
        {seen ? (
          <ZiptiText
            position="absolute"
            top={{ base: "1rem", md: "8rem" }}
            left={{ base: "43vw", md: "48vw" }}
            color="red.200"
            w="40vw"
          >
            {props.isExpert ? null : "Response Requested"}
          </ZiptiText>
        ) : null}
        {seen && !props.isExpert ? (
          <ZiptiButton
            position="absolute"
            right={{ base: "16rem", md: "44rem" }}
            borderRadius="100%"
            w="3rem"
            h="3rem"
            onClick={props.isExpert ? handleClickDenied : handleClickAllowed}
          >
            Clarify
          </ZiptiButton>
        ) : null}

        <ZiptiText
          position="absolute"
          fontSize="1.5vh"
          top={{ base: "31vh", md: "52vh" }}
          left={{ base: "48vw", md: "57vw" }}
          w="30vw"
          color="gray.400"
        >
          {props.timeSinceCopy}
        </ZiptiText>
      </Box>
    )
  }

  return (
    <>
      {!props.isAccount
        ? renderClarifyingQuestionExpert()
        : renderClarifyingQuestionAccount()}
    </>
  )
}

export default ClarifyingQuestion
